@import "~antd/lib/style/themes/default";
@import "mixins";

.@{ant-prefix}-picker-dropdown {
  &.hide-footer {
    .@{ant-prefix}-picker-footer {
      display: none;
    }
  }
}

.box-tabs {
  .@{ant-prefix}-tabs-nav {
    margin-bottom: 0;
  }

  .@{ant-prefix}-card {
    border-top: 0;

    &-body {
      .below-desktop({
        padding: 1rem;
      });
    }
  }
}

.@{ant-prefix}-upload {
  &.@{ant-prefix}-upload-drag {
    .@{ant-prefix}-upload {
      padding: 1rem;
    }

    p.@{ant-prefix}-upload-text {
      margin-bottom: 0.5rem;
      font-size: inherit;
    }
  }
}
