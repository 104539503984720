.site-name {
  display: flex;
  align-items: center;

  a {
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.65;
    }
  }

  &-logo {
    height: 2rem;
    margin-right: 1rem;
  }

  &-title {
    font-size: 16px;
    font-weight: 500;
  }
}
