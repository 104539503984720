@import "mixins";

.definitions {
  margin-bottom: 0;

  &.is-stack {
    .definition {
      flex-direction: column;

      &-title {
        width: 100%;
      }

      &-description {
        margin-top: 0.5rem;
        padding-left: 0;
      }
    }
  }

  .definition {
    display: flex;

    p {
      margin-bottom: 0.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }

    & + .definition {
      margin-top: 1rem;
    }

    &-title {
      flex: 0 0 auto;
      width: 20%;
    }

    &-description {
      flex: 1 1 auto;
      padding-left: 1rem;
      margin-bottom: 0;

      &:empty {
        &::before {
          content: '-';
        }
      }
    }
  }
}

.below-desktop({
  .definitions {
    .definition {
      flex-direction: column;

      & + .definition {
        margin-top: 1rem;
      }

      &-title {
        width: 100%;
      }

      &-description {
        margin-top: 0.5rem;
        padding-left: 0;
      }
    }
  }
});
