@import "mixins";

.above-desktop(@rules) {
  @media screen and (min-width: @screen-lg + 1px) {
    @rules();
  }
}

.below-desktop(@rules) {
  @media screen and (max-width: @screen-lg) {
    @rules();
  }
}
