@import '~antd/lib/style/themes/default';

.page-title {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: @heading-color;
  font-size: 1rem;
  font-weight: normal;

  .@{ant-prefix}-avatar {
    margin-right: 1rem;
    color: @primary-color;
    background-color: tint(@primary-color, 84%);
  }

  &.is-danger {
    .@{ant-prefix}-avatar {
      color: @error-color;
      background-color: tint(@error-color, 88%);
    }
  }
}
