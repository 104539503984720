@import "~antd/lib/style/themes/default";
@import "mixins";

.raised {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  border: 0;
}

.half {
  width: 50%;
}

.fluid {
  width: 100%;
}

.fluid-desktop {
  .above-desktop({
    width: 100%;
  });
}

.fluid-mobile {
  .below-desktop({
    width: 100%;
  });
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-clickable {
  cursor: pointer;
  transition: opacity 320ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.m {
  &t {
    &-sm { margin-top: @margin-xs }
    &-md { margin-top: @margin-md }
    &-lg { margin-top: @margin-lg }
  }

  &b {
    &-sm { margin-bottom: @margin-xs }
    &-md { margin-bottom: @margin-md }
    &-lg { margin-bottom: @margin-lg }
  }
}

.below-desktop({
  .m {
    &t {
      &-sm-mobile { margin-top: @margin-xs }
      &-md-mobile { margin-top: @margin-md }
      &-lg-mobile { margin-top: @margin-lg }
    }
  
    &b {
      &-sm-mobile { margin-bottom: @margin-xs }
      &-md-mobile { margin-bottom: @margin-md }
      &-lg-mobile { margin-bottom: @margin-lg }
    }
  }
});

.desktop-only {
  .below-desktop({
    display: none;
  });
}

.mobile-only {
  .above-desktop({
    display: none;
  });
}
