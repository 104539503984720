@import "mixins";

.layout {
  min-height: 100vh;

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    z-index: 1;

    .above-desktop({
      justify-content: space-between;
    });

    .below-desktop({
      padding: 0 1rem;
    });
  }

  &-sider {
    padding-top: 2.5rem;

    .desktop-only;
  }

  &-main {
    display: flex;
    flex-direction: column;
  }

  &-page {
    flex: auto;
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    padding: 2rem 1rem;
  }

  &-footer {
    text-align: center;
  }
}
